import { connect } from 'react-redux'
import ReportHeader from 'components/ReportHeader'
import { selectPlant } from 'reports/dashboard/selectors'

const mapStateToProps = state => ({
  name: selectPlant(state).name,
  sticky: true
})

export default connect(mapStateToProps)(ReportHeader)
