import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectLocation,
  selectLocationReport
} from 'reports/dashboard/selectors'
import { reportShape } from 'reports/dashboard/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'
import DashboardLocationLink from 'reports/dashboard/DashboardLocationLink'

const DashboardLocation = ({ id, report, children }) => {
  return (
    <div className='report-item'>
      <div className='report-item__box'>
        <div className='report-item__title'>
          <DashboardLocationLink id={id} />
        </div>

        <ReportGraph values={report.production} character={Characters.PRODUCTION} small showLabel={false} />
        <ReportGraph values={report.stoppagePlanned} character={Characters.STOPPAGE_PLANNED} small showLabel={false} />
        <ReportGraph values={report.stoppageUnplanned} character={Characters.STOPPAGE_UNPLANNED} small showLabel={false} />
        <ReportGraph values={report.stoppagePrototyping} character={Characters.STOPPAGE_PROTOTYPING} small showLabel={false} />
        <ReportGraph values={report.scrap} character={Characters.SCRAP} small showLabel={false} />
        {children}
      </div>
    </div>
  )
}

DashboardLocation.propTypes = {
  id: PropTypes.number.isRequired,
  report: reportShape,
  children: PropTypes.any
}

const mapStateToProps = (state, { id }) => ({
  ...selectLocation(state, id),
  report: selectLocationReport(state, id)
})

export default connect(mapStateToProps)(DashboardLocation)
