import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectLocation } from 'reports/dashboard/selectors'
import { selectNavigationScope, selectNavigationDate } from 'containers/Navigation/selectors'
import { DATE_FORMAT } from 'lib/const'

const DashboardLocationLink = ({ id, name, date, scope }) => {
  return (
    <a
      href={`/workspace/realization/${id}/${scope}/${date.format(DATE_FORMAT)}`}
    >
      {name}
    </a>
  )
}

DashboardLocationLink.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.object,
  scope: PropTypes.string
}

const mapStateToProps = (state, { id }) => ({
  name: selectLocation(state, id).name,
  date: selectNavigationDate(state),
  scope: selectNavigationScope(state)
})

export default connect(mapStateToProps)(DashboardLocationLink)
