import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'reports/dashboard/actions'
import { allIds, allById, idsByCustom, customById } from 'lib/reducers'
import navigation from 'containers/Navigation/reducer'

const emptyReport = () => ({ production: [], stoppagePlanned: [], stoppageUnplanned: [], stoppagePrototyping: [], scrap: [] })

const extractReport = ({ production, stoppagePlanned, stoppageUnplanned, scrap, stoppagePrototyping }) => (
  { production, stoppagePlanned, stoppageUnplanned, scrap, stoppagePrototyping }
)

const handleReceiveReports = (type) => (state, { payload: reports }) => ({
  ...state,
  reportById: customById(
    reports.filter(report => report.type === type),
    extractReport
  )
})

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const plant = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, report: emptyReport() }),
    [Actions.RECEIVE_REPORTS]: (state, { payload: reports }) => ({
      ...state,
      report: reports.length > 0 ? reports.filter(s => s.type === 'plant')[0] : {}
    })
  },
  { name: '', report: emptyReport() }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const sectors = handleActions(
  {
    [Actions.RECEIVE_SECTORS]: (state, { payload: sectors }) => ({
      allById: allById(sectors),
      allIds: allIds(sectors),
      reportById: customById(sectors, emptyReport())
    }),
    [Actions.RECEIVE_REPORTS]: handleReceiveReports('sector')
  },
  { allIds: [], reportById: {} }
)

const locations = handleActions(
  {
    [Actions.RECEIVE_SECTORS]: (state, { payload: sectors }) => ({
      ...state,
      idsBySector: customById(sectors, [])
    }),
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      allById: allById(locations),
      idsBySector: { ...state.idsBySector, ...idsByCustom(locations, 'sectorId') },
      reportById: customById(locations, emptyReport())
    }),
    [Actions.RECEIVE_REPORTS]: handleReceiveReports('location')
  },
  { allById: {}, idsBySector: {}, reportById: {} }
)

export default combineReducers({
  config,
  plant,
  init,
  navigation,
  sectors,
  locations
})
