import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectSector,
  selectSectorReport
} from 'reports/dashboard/selectors'
import { reportShape } from 'reports/dashboard/shapes'
import { Characters } from 'lib/const'
import ReportGraph from 'components/ReportGraph'

const DashboardSector = ({ id, report, sector, children }) => {
  return (
    <div className='report-section'>
      <div className='report-section__data'>
        <h1 className='report-section__title'>
          {sector.name}
        </h1>
        <div className='report-section__graphs'>
          <ReportGraph values={report.production} character={Characters.PRODUCTION} small showLabel={false} />
          <ReportGraph values={report.stoppagePlanned} character={Characters.STOPPAGE_PLANNED} small showLabel={false} />
          <ReportGraph values={report.stoppageUnplanned} character={Characters.STOPPAGE_UNPLANNED} small showLabel={false} />
          <ReportGraph values={report.stoppagePrototyping} character={Characters.STOPPAGE_PROTOTYPING} small showLabel={false} />
          <ReportGraph values={report.scrap} character={Characters.SCRAP} small showLabel={false} />
        </div>
      </div>

      <div className='report-section__locations'>
        {children}
      </div>
    </div>
  )
}

DashboardSector.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.any,
  report: reportShape,
  sector: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  report: selectSectorReport(state, id),
  sector: selectSector(state, id)
})

export default connect(mapStateToProps)(DashboardSector)
